function mapCartObject(apiResponse) {
  const {
    status,
    message,
    items = [],
    totals = [],
    coupon,
    shippingOptions,
    isAboActive,
    paymentMethods,
    canCheckout = false,
    reorderPaymentMethods,
    aboInterval,
  } = apiResponse

  return {
    status,
    message,
    items: items.map(mapCartItem),
    totals: totals,
    couponCode: coupon,
    shippingOptions,
    paymentMethods,
    reorderPaymentMethods,
    canCheckout,
    isAboActive,
    aboInterval,
  }
}

function mapCartItem({
  id,
  itemProductId,
  sku,
  name,
  manufacturer,
  url,
  image,
  badge,
  price,
  strikePrice,
  finalPrice,
  aboPrice,
  selectedOptions,
  config_variable_a,
  qty,
  maxQty,
  outOfStock,
  outOfQty,
  isAbo,
  subscriptionPercentage,
  subscriptionSavings,
}) {
  return {
    product: {
      id,
      aboId: itemProductId,
      sku,
      name,
      manufacturer,
      url,
      image,
      badge,
      price,
      strikePrice,
      aboPrice,
      finalPrice,
      selectedOptions,
      config_variable_a,
      subscriptionPercentage,
      subscriptionSavings,
    },
    qty,
    maxQty,
    outOfStock,
    outOfQty,
    isAboEnabled: isAbo,
  }
}

export { mapCartObject }
